import { Heading, Center, Container, Text, Stack, Box, Image, AspectRatio, Grid, GridItem } from '@chakra-ui/react'
import stage1 from '../images/stage1.jpg';
import stage2 from '../images/stage2.jpg';
import stage3 from '../images/stage3.jpg';
import stagefinal from '../images/stagefinal.jpg';
import mic from '../images/mic.jpg';
import first from '../images/16sep.jpg';
import second from '../images/23sep.jpg';
import artist16 from '../images/artist16.png';
import artist23 from '../images/artist23.png';
import final from '../images/final.jpg';
import finalvid from './final.mp4';

function Home() {
  return (
      <Center>
        <Box color="white">
        <Box maxW='100%'>
                <video controls loop autoplay width='100%' height='100vh'>
                    <source src={finalvid} type="video/mp4"/>
                </video>
                </Box>
        <Center minH='50vh' backgroundImage={stagefinal} backgroundPosition="center"
                    backgroundRepeat="no-repeat" backgroundSize='cover'>
                <Grid templateColumns='repeat(2, 1fr)' gap={1} display={{ lg: 'grid', sm: 'block' }} minH='50vh' bgColor='blackAlpha.600'>
                    <Center>
                    <Heading size='4xl' mb={{lg: 5, base: 1}}>GROOT <br /> FINAAL</Heading>
                    </Center>
                    <GridItem>
                    <Center minH='50vh' p={3}>
                        <Stack>
                    <Text mb={2}>Die beoordelingspaneel vir die Groot Finaal bestaan uit Paul Riekert (Battery 9), Jolyn Phillips (Die Wasgoedlyn), Augusta Zietsman, Jaco van der Merwe (Bittereinder/Walkie Talkie), en Henk  van der Schyf (Droomland/Easy Agency/Lentedag/Mieliepop/Park Acoustics).</Text>
                    <Text mb={2}>Die algehele wenner van die kompetisie kry die geleentheid om twee snitte saam met Peach van Pletzen by Planet Awesome in Johannesburg op te neem en een musiekvideo te vervaardig saam met André Badenhorst en Alan Sayers. Die naaswenner wen een snit by Planet Awesome.</Text>
                    </Stack>
                    </Center>
                    </GridItem>
                    <Center>
                    <Stack>
                    <Image m={4} height="80vh" src={final} />
                    </Stack>
                    </Center>
                    <Center p={2}>
                        <Stack>
                        <Heading>30 SEPTEMBER 2023</Heading>
                    <Text>TAALGENOOT-RUK EN RYM: DIE GROOT FINAAL</Text>
                    <Text>SOGNAGE, JHB</Text>
                    <Text>15:00</Text>
                    <Text>R100 aanlyn | R120 by die deur</Text>
                    <Text mb={2}>SPESIALE OPTREDE DEUR ZAAN SONNEKUS AAN DIE EINDE VAN DIE AAND.</Text>
                    <Text>Kaartjies beskikbaar</Text>
                    <Heading><a href="https://plankton.mobi/Events/EventBooking/28ba7f41-ef7a-430b-a782-cb01e141ca85">HIER</a></Heading>
                    </Stack>
                    </Center>
                </Grid>
            </Center>
            <Box backgroundImage={stage1} backgroundPosition="left"
                    backgroundRepeat="no-repeat" backgroundSize='cover'>
            <Grid templateColumns='repeat(2, 1fr)' gap={2} display={{ lg: 'grid', sm: 'block' }} minH='50vh' bgColor='blackAlpha.600'>
            <Center minH='100%' display={{lg: 'none', base: 'Flex'}}>
            <Heading size={{lg: '4xl', base: '2xl'}} mb={{lg: 5, base: 1}}>DIE <br /> SEMIFINALISTE <br /> IS BEKEND</Heading>
            </Center>
            <Center pl={{lg: 5, base: 0}}>
            <Stack textAlign={{lg: "end", base: "start"}}>
            <Text m={2}>Die inskrywings is almal in! Die keurpaneel, wat bestaan het uit Hunter Kennedy (Fokofpolisiekar/Die Heuwels Fantasties), Peach van Pletzen (Van Pletzen/Bittereinder), Jerome Rex (organiseerder van Expression Session en ATKV-Crescendo-vennoot) en Jaco van der Merwe (Bittereinder/Walkie Talkie) het na meer as 70 kunstenaars se musiek geluister en gesamentlik besluit op die 18 semifinaliste wat op 16 en 23 September in die semifinale gaan meeding vir ’n plek in die Groot Finaal (by Sognage op 30 September). Baie geluk aan al die semifinaliste!</Text>
            </Stack>
            </Center>
            <Center minH='100%' display={{lg: 'flex', base: 'none'}}>
            <Heading size={{lg: '4xl', base: '2xl'}} mb={{lg: 5, base: 1}}>DIE <br /> SEMIFINALISTE <br /> IS BEKEND</Heading>
            </Center>
            </Grid>
            </Box>
            <Box minH='50vh' backgroundImage={mic} backgroundPosition="center"
                    backgroundRepeat="no-repeat" backgroundSize='cover'>
                <Box pt={4} pb={4} minH='30vh' bgColor='blackAlpha.600'>
                    <Center>
                    <Heading size='3xl' mb={{lg: 5, base: 0}}>DIE SEMIFINAAL</Heading>
                    </Center>
                <Grid templateColumns='repeat(2, 1fr)' gap={2} display={{ lg: 'grid', sm: 'block' }}>              
                <GridItem>
                    <Center>
                    <Stack>
                    <Image height="80vh" src={first} />
                    </Stack>
                    </Center>
                    <Center p={2}>
                        <Stack>
                        <Heading>16 SEPTEMBER 2023</Heading>
                    <Text>TAALGENOOT-RUK EN RYM SEMIFINAAL 1: RAILWAYS CAFÉ, IRENE</Text>
                    {/* <Text>Optredes begin om 15:00.</Text>
                    <Text>R100 aanlyn | R120 by die deur</Text>
                    <Text>Kaartjies beskikbaar</Text>
                    <Heading><a href="https://plankton.mobi/Events/EventBooking/62ad697c-fc8e-453d-a748-1f4139f41f5e">HIER</a></Heading> */}
                    </Stack>
                    </Center>
                    <Center>
                    <Stack>
                    <Image p={4} width="100%" src={artist16} />
                    </Stack>
                    </Center>
                    </GridItem>
                    <GridItem>
                    <Center>
                    <Stack>
                    <Image height="80vh" src={second} />
                    </Stack>
                    </Center>
                    <Center p={2}>
                        <Stack>
                        <Heading>23 SEPTEMBER 2023</Heading>
                    <Text>TAALGENOOT-RUK EN RYM SEMIFINAAL 2: JARR BAR, PTA</Text>
                    {/* <Text>Optredes begin om 12:00.</Text>
                    <Text>R100 aanlyn | R120 by die deur</Text>
                    <Text>Kaartjies beskikbaar</Text>
                    <Heading><a href="https://plankton.mobi/Events/EventBooking/80361d1e-4096-4eb2-a826-06b922557154">HIER</a></Heading> */}
                    </Stack>
                    </Center>
                    <Center>
                    <Stack>
                    <Image p={4} width="100%" src={artist23} />
                    </Stack>
                    </Center>
                    </GridItem>
                    </Grid>  
                    
                </Box>
            </Box>
            <Center minH='50vh' backgroundImage={stage2} backgroundPosition="center"
                    backgroundRepeat="no-repeat" backgroundSize='cover'>
                <Grid p={5} templateColumns='repeat(2, 1fr)' gap={2} display={{ lg: 'grid', sm: 'block' }} minH='50vh' bgColor='blackAlpha.600'>
                    <Center>
                    <Heading size='4xl' mb={{lg: 5, base: 1}}>WAT IS <br /> RUK EN RYM?</Heading>
                    </Center>
                    <GridItem>
                    <Center minH='50vh'>
                        <Stack>
                    <Text mb={2}><i>Taalgenoot</i>-RUK EN RYM is ’n musiekkompetisie, gebaseer op die destydse Rockspaaider-kompetisie wat deur MK en <i>JIP</i> aangebied is. Nog voor dit, tussen 1995 en 2010, was daar ’n verskeidenheid <i>battle of the bands</i>-kompetisies waaraan jong en opkomende Suid-Afrikaanse musikante kon deelneem, maar daar was in die onlangse verlede ’n tekort aan sulke kompetisies. Die tyd is dus ryp om ’n nuwe een te stig. </Text>
                    <Text mb={2}>RUK EN RYM is oop vir enige klankskeppende kunstenaars wat alternatiewe musiek met Afrikaanse lirieke maak. Wat beteken “alternatief”? Dit beteken enigiets wat afwyk van die hoofstroom – kunstenaars wat innoveer en wat die strewe na vars klanke en ritmes hoër ag as om neigings te volg. Sanger-liedjieskrywers, <i>bands</i>, groepe, kletsrymers, elektroniese musikante en enigiemand wat oorspronklike musiek maak en Afrikaans in die lirieke gebruik, mag inskryf. As jy dink jy het ’n talent, ’n liedjie of ’n oorspronklike idee wat gehoor moet word, skryf in!</Text>
                    </Stack>
                    </Center>
                    </GridItem>
                </Grid>
            </Center>
        </Box>
      </Center> 
  );
}

export default Home;