import {
	GridItem,
	Grid,
	Heading,
	Text,
	Center,
	Container,
	Box,
	Flex,
} from "@chakra-ui/react";

function Info() {
	return (
		<Container maxW='90%'>
			<Grid
				templateColumns='repeat(2, 1fr)'
				gap={1}
				display={{ lg: "grid", sm: "block" }}
				minH='100vh'
			>
				<GridItem px={1}>
					<Flex
						minH={{ lg: "90vh", base: "40vh" }}
						justify='center'
						textAlign='left'
						flexDirection='column'
					>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							MUSIKANTE!
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							LIEDJIESKRYWERS!
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							KLANKSKEPPENDES!
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							SKRYF IN!
						</Heading>
					</Flex>
				</GridItem>
				<Center py={5} px={1}>
					<Text fontSize='150%'>
						<i>Taalgenoot</i>-RUK EN RYM is oop vir enige klankskeppende
						kunstenaars wat alternatiewe musiek met Afrikaanse lirieke maak. Wat
						beteken “alternatief”? Dit beteken enigiets wat afwyk van die
						hoofstroom – kunstenaars wat innoveer en wat die strewe na vars
						klanke en ritmes hoër ag as om neigings of formules te volg.
						Sanger-liedjieskrywers, <i>bands</i>, groepe, kletsrymers,
						elektroniese musikante en enigiemand wat oorspronklike musiek maak
						en meestal Afrikaans in die lirieke gebruik, mag inskryf. Daar is
						geen ouderdomsbeperking en geen inskrywingsfooi nie. Behalwe die
						drie wenkunstenaars van ’23, mag kunstenaars wat in 2023 ingeskryf
						het, weer in 2024 inskryf. As jy dink jy het ’n talent, ’n liedjie
						of ’n oorspronklike idee wat gehoor moet word, skryf in!
					</Text>
				</Center>
			</Grid>
		</Container>
	);
}

export default Info;
