import { Center, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Archive({name, link}) {
  return (
      <>
        <Center h="200px">
            <Link to={link}>
                <Center m={3} _hover={{color: "#C3322B", transition: '0.3s', borderColor: "#C3322B"}} h='133px' border='2px' borderColor='white' borderRadius="20px">
                    <Heading py={3} px={5} size="4xl" fontSize={{ lg: '750%', base: '400%' }}>{name}</Heading>
                </Center>
            </Link>
        </Center>
      </>
  );
}

export default Archive;