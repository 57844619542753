import { Flex, Box, Spacer, Heading, Text, Center, Image, Container } from "@chakra-ui/react";
import logo from '../images/logo-wit.png';
import SocialMedia from "./SocialMedia";
import { FaRegCopyright } from "react-icons/fa6";

function Footer() {
  return (
      <Box minH="10vh" bg="black">
        <Container maxW='90%'>
        <Center>
            <SocialMedia />
        </Center>
        <Flex>
            <Box p='4'>
                <Image h="50px" src={logo} />
            </Box>
            <Spacer />
            <Center p='4'>
            <FaRegCopyright /><Text ml="2"> 2024 Ruk en Rym</Text>
            </Center>
        </Flex>
        </Container>
      </Box>
  );
}

export default Footer;