import { Flex, Box, Spacer, Image, Text, Container } from "@chakra-ui/react";
import logo from "../images/logo-wit.png";
import SocialMedia from "./SocialMedia";

function Navbar() {
	return (
		<>
			<Box width='100%' zIndex='999' position={"fixed"} bg='black'>
				<Flex maxW='100vw'>
					<Box>
						<Image p='2' h={{ base: "8vh", md: "10vh" }} src={logo} />
					</Box>
					<Spacer display={{ base: "none", sm: "block" }} />
					<Box p={{ base: "0", md: "2" }}>
						<SocialMedia />
					</Box>
				</Flex>
			</Box>
		</>
	);
}

export default Navbar;
