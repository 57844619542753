import {
	GridItem,
	Grid,
	Heading,
	Text,
	Center,
	Container,
	Box,
	Flex,
	Divider,
} from "@chakra-ui/react";
import AboutHero from "../images/About-Hero.jpg";

function About() {
	return (
		<>
			<Box
				backgroundImage={AboutHero}
				backgroundPosition='center'
				backgroundRepeat='no-repeat'
				width='100%'
				backgroundSize='cover'
			>
				<Box bgColor='blackAlpha.600'>
					<Container maxW='90%'>
						<Grid
							templateColumns='repeat(2, 1fr)'
							gap={1}
							display={{ lg: "grid", sm: "block" }}
							minH='80vh'
						>
							<GridItem px={1}>
								<Flex
									minH={{ lg: "60vh", base: "40vh" }}
									justify='center'
									textAlign='left'
									flexDirection='column'
								>
									<Heading
										mb={{ lg: "-8", base: "0" }}
										size='4xl'
										fontSize={{ lg: "750%", base: "600%" }}
									>
										WAT IS
									</Heading>
									<Heading
										mb={{ lg: "-8", base: "0" }}
										size='4xl'
										fontSize={{ lg: "750%", base: "500%" }}
									>
										RUK EN RYM?
									</Heading>
								</Flex>
							</GridItem>
							<Center py={5} px={1}>
								<Text fontSize='150%'>
									Tussen 1995 en 2010 was daar heelwat{" "}
									<i>battle of the bands</i>-kompetisies wat geleenthede gebied
									het vir opkomende Suid-Afrikaanse musikante om ontdek te word
									en om pryse te wen wat as wegspringplek vir hul musikale
									loopbane kon dien. Daar was in die onlangse verlede ’n tekort
									aan sulke kompetisies, wat nou die ideale tyd maak om ’n nuwe
									een te loods.
								</Text>
							</Center>
						</Grid>
					</Container>
					<Grid
						bgGradient='linear(blackAlpha.50 0%, blackAlpha.400 10%, #0E0E0E 70%)'
						templateColumns='repeat(2, 1fr)'
						gap={1}
						display={{ lg: "grid", sm: "block" }}
						minH='10vh'
					>
						<Center
							display={{ lg: "block", md: "none", base: "none" }}
							ml='10%'
							maxW='88%'
						>
							<Divider h='0.5px' w='95%' bg='white' />
						</Center>
						<Center
							display={{ lg: "block", md: "none", base: "none" }}
							mr='10%'
							maxW='87%'
						>
							<Divider ml='1' h='0.5px' w='100%' bg='white' />
						</Center>
					</Grid>
				</Box>
			</Box>
			<Container maxW='90%'>
				<Grid
					pb={5}
					templateColumns='repeat(2, 1fr)'
					gap={1}
					display={{ lg: "grid", sm: "block" }}
				>
					<Box py={5}>
						<Text pr={{ lg: "5%", base: "0" }} fontSize='150%'>
							<i>Taalgenoot</i>-RUK EN RYM is vir die eerste keer in 2023 gehou
							en het ’n groep opwindende musikante van regoor die land
							byeengebring – kunstenaars wat vars klanke in ’n wye
							verskeidenheid musiekgenres skep – en ’n unieke gemeenskap van
							eendersdenkende kreatiewe mense is gevorm. Die keurpaneel het deur
							byna 100 inskrywings geluister, en uiteindelik is 18 semifinaliste
							uit plekke so uiteenlopend soos Kimberley, Lichtenburg, Okiep,
							Kathu, Kempton Park, Kaapstad, Stellenbosch, Potchefstroom,
							Bloemfontein en Centurion gekies.
						</Text>
					</Box>
					<Box py={5} px={1}>
						<Text fontSize='150%'>
							RUK EN RYM 2024 beloof om groter en beter te wees en om aan
							musikante ’n platform en geleentheid te bied wat nêrens anders
							beskikbaar is nie. Die semifinale rondes word in September 2024 by
							Railways Café in Gauteng en Daisy Jones Bar in Stellenbosch gehou,
							terwyl die Groot Finaal by Sognage in Johannesburg sal plaasvind.
							Pryse sluit ateljeetyd, musiekvideo’s en optredes in.
						</Text>
					</Box>
				</Grid>
			</Container>
		</>
	);
}

export default About;
