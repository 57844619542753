import { createHashRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Index from "./archive/2023/site/Index";
import Home from "./pages/Home";
import Kaartjies from "./pages/Kaartjies";
import RootLayout from "./pages/RootLayout";
import Gallery from './pages/Gallery';


function App() {

  const router = createHashRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route path="/" element={
            <Home />
        } />
        <Route path="/2023" element={
            <Index />
        } />
        <Route path="/KoopKaartjies" element={
            <Kaartjies />
        } />
        <Route path="/Galery" element={
            <Gallery />
        } />
      </Route>
    )
  )

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;

