import {
	GridItem,
	Grid,
	Heading,
	Text,
	Center,
	Container,
	Box,
	Flex,
	Button,
	Image,
} from "@chakra-ui/react";

function Ticket({ name, location, city, date, link }) {
	return (
		<Container maxW='90%'>
			<Grid
				templateColumns='repeat(2, 1fr)'
				gap={1}
				display={{ lg: "grid", sm: "block" }}
			>
				<GridItem px={1}>
					<Flex
						minH={{ lg: "90vh", base: "40vh" }}
						justify='center'
						flexDirection='column'
					>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							{name}
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							{location}
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							{city}
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							{date}
						</Heading>
					</Flex>
				</GridItem>
				<Center py={5} px={1}>
					{link ? (
						<a target='_blank' href={link}>
							<Center
								m={3}
								_hover={{
									color: "#C3322B",
									transition: "0.3s",
									borderColor: "#C3322B",
								}}
								h='133px'
								border='2px'
								borderColor='white'
								borderRadius='20px'
							>
								<Heading
									py={3}
									px={5}
									size='4xl'
									fontSize={{ lg: "750%", base: "400%" }}
								>
									KOOP KAARTJIES
								</Heading>
							</Center>
						</a>
					) : (
						<Center
							m={3}
							_hover={{
								color: "#C3322B",
								transition: "0.3s",
								borderColor: "#C3322B",
							}}
							textAlign='center'
							border='2px'
							borderColor='white'
							borderRadius='20px'
						>
							<Heading
								py={3}
								px={5}
								size='4xl'
								fontSize={{ lg: "750%", base: "400%" }}
							>
								KAARTJIES BINNEKORT BESKIKBAAR
							</Heading>
						</Center>
					)}
				</Center>
			</Grid>
		</Container>
	);
}

export default Ticket;
