import {
	GridItem,
	Grid,
	Heading,
	Text,
	Center,
	Container,
	Box,
	Flex,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";

function SelectionPanel() {
	return (
		<Container maxW='90%'>
			<Grid
				templateColumns='repeat(2, 1fr)'
				gap={1}
				display={{ lg: "grid", sm: "block" }}
				minH='100vh'
			>
				<GridItem px={1}>
					<Flex
						minH={{ lg: "90vh", base: "40vh" }}
						justify='center'
						textAlign='left'
						flexDirection='column'
					>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							Die
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							2024
						</Heading>
						<Heading
							mb={{ lg: "-8", base: "0" }}
							size='4xl'
							fontSize={{ lg: "750%", base: "400%" }}
						>
							KEURPANEEL
						</Heading>
					</Flex>
				</GridItem>
				<Center flexDir='column' py={5} px={1}>
					<Text fontSize='150%'>
						Die keurpaneel bestaan uit Hunter Kennedy (Fokofpolisiekar/Die
						Heuwels Fantasties), Albert “Vuurwolf” van der Merwe (Spoegwolf),
						Jerome Rex (organiseerder van Expression Session en
						ATKV-Crescendo-vennoot), Rosey die Rapper, Jaco van der Merwe
						(Bittereinder/Walkie Talkie).
					</Text>
					<Text fontSize='150%'>
						Inskrywings sluit om middernag (24:00) op 26 Augustus 2024. Al die
						semifinaliste sal op 28 Augustus aangekondig word, en sal dan in die
						semifinale uitdunrondes meeding op:
					</Text>
					<UnorderedList>
						<ListItem p={2}>
							14 September (Railways Café Centurion), of
						</ListItem>
						<ListItem p={2}>2 Oktober (Daisy Jones Bar, Stellenbosch)</ListItem>
						<Text fontSize='150%'>
							Die finale ronde sal op 12 Oktober by Sognage in Johannesburg
							plaasvind.
						</Text>
					</UnorderedList>
				</Center>
			</Grid>
		</Container>
	);
}

export default SelectionPanel;
