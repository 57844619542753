import { Center, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function LinkTo({name, link}) {
  return (
      <>
        <Center h="200px" bg="#C3322B">
            <Link target='_blank' to={link}>
                <Center m={3} _hover={{color: "black", transition: '0.3s', borderColor: "black"}} h='133px' border='2px' borderColor='white' borderRadius="20px">
                    <Heading py={3} px={5} size="4xl" fontSize={{ lg: '750%', base: '400%' }}>{name}</Heading>
                </Center>
            </Link>
        </Center>
      </>
  );
}

export default LinkTo;