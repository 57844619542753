import {
	Box,
	Image,
	Center,
	Grid,
	Stack,
	Flex,
	Spacer,
	AspectRatio,
} from "@chakra-ui/react";
import hero from "../images/Guitar.jpg";
import logo from "../images/logo-wit-taalgenoot.png";
import pa from "../images/pa.png";
import railways from "../images/Railways.png";
import sognage from "../images/Sognage.png";
import spotify from "../images/Spotify.png";
import alwyn from "../images/Alwyn.png";
import woordfees from "../images/Woordfees.png";
import wereld from "../images/Wereld.png";
import steyn from "../images/Steyn.png";
import marshall from "../images/MM.png";

function Hero() {
	return (
		<Box
			backgroundImage={hero}
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
			maxWidth='100vw'
			backgroundSize='cover'
		>
			<Center minH='100vh'>
				<Stack mt={{ base: "15vh", md: "" }}>
					<Center pb='10'>
						<Image src={logo} width={{ base: "90%", md: "60%" }} />
					</Center>
					<Center display={{ base: "yes", md: "none" }} minW='vw'>
						<Grid templateColumns='repeat(3, 1fr)' gap={3}>
							<Image src={woordfees} width='25vw' />
							<Image src={wereld} width='25vw' />
							<Image src={steyn} width='25vw' />
							<Image src={spotify} width='25vw' />
							<Image src={sognage} width='25vw' />
							<Image src={railways} width='25vw' />
							<Image src={pa} width='25vw' />
							<Image src={marshall} width='25vw' />
							<Image src={alwyn} width='25vw' />
						</Grid>
					</Center>
					<Center display={{ base: "none", md: "yes" }} direction='row'>
						<Image src={woordfees} width='8vw' m='3' />
						<Image src={wereld} width='8vw' m='3' />
						<Image src={steyn} width='8vw' m='3' />
						<Image src={spotify} width='8vw' m='3' />
						<Image src={sognage} width='8vw' m='3' />
						<Image src={railways} width='8vw' m='3' />
						<Image src={pa} width='8vw' m='3' />
						<Image src={marshall} width='8vw' m='3' />
						<Image src={alwyn} width='8vw' m='3' />
					</Center>
				</Stack>
			</Center>
		</Box>
	);
}

export default Hero;
