import { Center, Container, Heading, ListItem, UnorderedList } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Conditions() {
  return (
      <>
      <Container maxW={{ lg: '70%', base: '100%' }}>
        <Center flexDir='column'>
          <Heading py={3} px={5} size="4xl" fontSize={{ lg: '750%', base: '400%' }}>BEPALINGS EN VOORWAARDES</Heading>
          <UnorderedList>
            <ListItem p={2}><i>Taalgenoot</i>-RUK EN RYM is oop vir enige klankskeppende kunstenaars wat alternatiewe musiek met Afrikaanse lirieke maak. Groepe wat musiek met Engelse lirieke maak, mag inskryf, maar sal waarskynlik nie deurdring na die semifinale rondes nie.
            </ListItem>
            <ListItem p={2}>Groepe en kunstenaars word afgeraai om in te skryf met lirieke wat diskriminerende taal ten opsigte van geslag, ras, seksuele oriëntasie, geloofsoortuiging, ens. bevat. Sou sodanige taalgebruik in die lirieke voorkom, moet daar baie goeie artistieke regverdiging daarvoor wees.
            </ListItem>
            <ListItem p={2}>Die keurders en beoordelaars se besluit is finaal en geen korrespondensie sal gedurende of na afloop van die kompetisie daaroor gevoer word nie.
            </ListItem>
            <ListItem p={2}>Deur die indiening van die inskrywingsvorm bevestig RUK EN RYM-deelnemers dat hulle kennis neem van die ATKV se artikel 18-kennisgewing op sy webtuiste (atkv.org.za) en toestem dat persoonlike inligting ingesamel mag word; en/of dat die indiening van die inskrywingsvorm bevestig dat die RUK EN RYM-deelnemers kennis dra dat die ATKV nié hoef te voldoen aan die verpligtings wat in artikel 18(1) van die Wet op die Beskerming van Persoonlike Inligting neergelê is nie; en/of dat die indiening van die inskrywingsvorm bevestig dat die ATKV spesiale persoonlike inligting, soos waarna verwys word in artikel 26 van die Wet op die Beskerming van Persoonlike Inligting, mag insamel.
            </ListItem>
            <ListItem p={2}>Die kunstenaars of groepe wat inskryf, vrywaar die ATKV teen enige eise wat mag ontstaan as gevolg van skade of verlies van watter aard ook al wat sodanige kunstenaars of groepe gedurende die hele verloop van die kompetisie kan ly.
            </ListItem>
            <ListItem p={2}>Deur hul inskrywings en deelname onderwerp RUK EN RYM-deelnemers hulle aan die reëls soos wat dit deur die ATKV/Taalgenoot bepaal is.
            </ListItem>
            <ListItem p={2}>Die ATKV/Taalgenoot behou hom die reg voor om die kompetisie enige tyd te staak of beëindig indien buitengewone omstandighede dit noodsaak.
            </ListItem>
          </UnorderedList>
        </Center>
        </Container>
      </>
  );
}

export default Conditions;