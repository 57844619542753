import Home from './Home';
import { Box, Image, Center, Grid, Stack, Flex, Spacer, AspectRatio } from '@chakra-ui/react'
import logo from '../images//logo-wit.png';
import hero from '../images/hero.jpg';
import pa from '../images/pa.png';
import wereld from '../images/wereld.png';
import nameless from '../images/nameless.png';

function Index() {

  return (
    <>
      <Box backgroundImage={hero} backgroundPosition="center"
  backgroundRepeat="no-repeat" width='100%' backgroundSize='cover'>
    <Center minH="100vh">
    <Stack>
        <Image src={logo} width='100%' />
        <Center dir="row">
        <Image mb={5} src={pa} width='10%' />
        <Image ml={{lg: 5, base: 2}} mb={5} src={wereld} width='10%' />
        <Image mb={5} src={nameless} width='10%' />
        </Center>
        </Stack>
        </Center>
        </Box>
      <Home />
    </>
  );
}

export default Index;
