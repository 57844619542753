import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const Button = {
  variants: {
    primary: {
      mb: '1rem',
      mt: '1rem',
      bg: '#3182CE',
      color: 'white',
      _hover: {
        bg: '#5DC9C9',
        transition: '0.3s',
      },
    }
  },
  defaultProps: {
    variant: 'primary',
  },
};

const theme = extendTheme({
  config,
  fonts: {
    heading: 'Knockout, sans-serif',
    body: 'AnotherGrotesk, sans-serif',
  },
  styles: {
    global: {
      'html, body': {
        color: 'white',
        bg: '#0E0E0E',
      },
    },
  },
  components: {
    Button,
  },
});

export default theme;
