import { GridItem, Grid, Container } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const importAllImages = (context) => {
  let images = {};
  context.keys().forEach((item) => {
    images[item.replace('./', '')] = context(item);
  });
  return images;
};

const images = importAllImages(require.context('../images/2023', false, /\.(png|jpe?g|svg)$/));

function Gallery() {
  const galleryImages = Array.from({ length: 40 }, (_, index) => ({
    number: index + 1,
    title: `image${index + 1}`,
    image: `image${index + 1}.jpg`,
  }));

  return (
    <>
    <Navbar />
    <Container pt="8%" maxW='90%'>
      <Grid templateColumns='repeat(4, 1fr)' gap={1} display={{ lg: 'grid', sm: 'block' }} minH='100vh'>
        {galleryImages.map((item, index) => (
          <GridItem key={index} m={3}>
            <img
              src={images[item.image]}
              className="img-fluid"
              alt={item.title}
            />
          </GridItem>
        ))}
      </Grid>
    </Container>
    <Footer />
    </>
  );
}

export default Gallery;
