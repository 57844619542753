import { Flex, Box, Spacer, Heading, Text, Center } from "@chakra-ui/react";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function SocialMedia() {
	return (
		<>
			<Flex ml={{ base: "2", md: "0" }} mt={{ base: "4", md: "1" }}>
				<Text
					_hover={{ color: "#C3322B", transition: "0.3s" }}
					m={{ base: "1", md: "2" }}
					fontSize={{ base: "150%", md: "200%" }}
				>
					<a href='https://www.facebook.com/profile.php?id=61559633333670'>
						<FaFacebookSquare />
					</a>
				</Text>
				<Text
					_hover={{ color: "#C3322B", transition: "0.3s" }}
					m={{ base: "1", md: "2" }}
					fontSize={{ base: "150%", md: "200%" }}
				>
					<a href='https://x.com/Taalgenoot'>
						<FaXTwitter />
					</a>
				</Text>
				<Text
					_hover={{ color: "#C3322B", transition: "0.3s" }}
					m={{ base: "1", md: "2" }}
					fontSize={{ base: "150%", md: "200%" }}
				>
					<a href='https://www.instagram.com/ruk_en_rym/'>
						<FaInstagram />
					</a>
				</Text>
				<Text
					_hover={{ color: "#C3322B", transition: "0.3s" }}
					m={{ base: "1", md: "2" }}
					fontSize={{ base: "150%", md: "200%" }}
				>
					<a href='https://www.youtube.com/@RUKENRYM'>
						<FaYoutube />
					</a>
				</Text>
			</Flex>
		</>
	);
}

export default SocialMedia;
