import {
	GridItem,
	Grid,
	Heading,
	Text,
	Center,
	Container,
	Box,
	Flex,
} from "@chakra-ui/react";

function Semifinalists() {
	return (
		<Container minH='15vh' maxW='90%'>
			<Center>
				<Heading
					mb={{ lg: "-8", base: "0" }}
					size='4xl'
					fontSize={{ lg: "750%", base: "400%" }}
				>
					DIE SEMIFINALISTE IS BEKEND
				</Heading>
			</Center>
			<Grid
				templateColumns='repeat(2, 1fr)'
				gap={1}
				display={{ lg: "grid", sm: "block" }}
			></Grid>
		</Container>
	);
}

export default Semifinalists;
