import { Center, Heading, Box } from "@chakra-ui/react";
import hero from "../images/Guitar.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Ticket from "../components/Ticket";

function Kaartjies() {
	return (
		<>
			<Navbar />
			<Box bgGradient='linear(to-r, #C3322B, black)' width='100%'>
				<Ticket
					name='SEMIFINAAL 1'
					location='Railways Café'
					city='Centurion'
					date='14 September'
					link='https://plankton.mobi/Events/EventBooking/7b83b923-a3c9-4e53-8c06-159f8b935935'
				/>
				<Ticket
					name='SEMIFINAAL 2'
					location='Daisy Jones Bar'
					city='Stellenbosch'
					date='2 Oktober'
					link='https://www.quicket.co.za/events/267814-taalgenoot-ruk-en-rym-semifinaal-met-karen-zoid/#/'
				/>
				<Ticket
					name='DIE GROOT FINAAL'
					location='Sognage'
					city='Randburg'
					date='12 Oktober'
				/>
			</Box>
			<Footer />
		</>
	);
}

export default Kaartjies;
