import { Center, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import Archive from "../components/Archive";
import Hero from "../components/Hero";
import Info from "../components/Info";
import About from "../components/About";
import Conditions from "../components/Conditions";
import Footer from "../components/Footer";
import LinkTo from "../components/LinkTo";
import SelectionPanel from "../components/SelectionPanel";
import Semifinalists from "../components/Semifinalists";

function Home() {
	return (
		<>
			<Navbar />
			<Hero />
			{/* <CallToAction name="INSKRYWINGSVORM" link="https://docs.google.com/forms/d/1au0mCFT6AQ-bo-682buzST5L5AdZVRD2IOlxvjwRHNk/edit" /> */}
			<Semifinalists />
			<LinkTo name='KOOP KAARTJIES' link='/KoopKaartjies' />
			<Info />
			<About />
			<LinkTo name='KOOP KAARTJIES' link='/KoopKaartjies' />
			<SelectionPanel />
			<Center bg='#C3322B' pt='5'>
				<Heading size='4xl' fontSize={{ lg: "750%", base: "400%" }}>
					KYK TERUG
				</Heading>
			</Center>
			<LinkTo name='GALERY' link='/Galery' />
			<Archive name='RUK EN RYM 2023' link='/2023' />
			<Conditions />
			<Footer />
		</>
	);
}

export default Home;
